import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { CategoryList } from "../interfaces/categories/category-list.model";
import { ParentCategory } from "../interfaces/categories/parent-category.model";

@Injectable({
    providedIn: "root",
})
export class CategorysService {
    private category = new BehaviorSubject<CategoryList | null>(null);
    private parentCategory = new BehaviorSubject<ParentCategory | null>(null);

    category$ = this.category.asObservable();
    parentCategory$ = this.parentCategory.asObservable();

    constructor(private http: HttpClient, private router: Router) {}

    getCategory(category: string | null, subcategory: string | null, currPage: number, perPage = 24) {
        let params = new HttpParams().set("per_page", perPage).set("page", currPage);
        return this.http
            .get<CategoryList>(`${environment.API_UNA}${environment.API_CATEGORY}/${category}/${subcategory}`, {
                params,
            })
            .subscribe((category: CategoryList) => {
                category.data.articles.forEach((e) => {
                    e["category"] = category.data.category;
                });
                this.category.next(category);
            });
    }

    getCategoryTest(subcategory: string | null) {
        return this.http.get<boolean>(`${environment.API_UNA}${environment.API_IS_CHILD_CATEGORY}/${subcategory}`);
    }

    getParentCategory(query: string | null, currPage: number, perPage = 24) {
        let params = new HttpParams().set("per_page", perPage).set("page", currPage);
        return this.http
            .get<ParentCategory>(`${environment.API_UNA}${environment.API_PARENT_CATEGORY}/${query}`, { params })
            .subscribe({
                next: (parentCat: ParentCategory) => {
                    this.parentCategory.next(parentCat);
                },
                error: (error: any) => {
                    this.router.navigate(["404"]);
                },
            });
    }
}
